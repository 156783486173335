.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.white-font {
  color: #ffffff;
}

.rochester {
  font-family: 'Rochester', cursive;
  font-weight: 100;
}

.nav-brand {
  text-decoration: none;
  color: black;
}

.home-container {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 120%;

}

.title {
  font-weight: 100;
  margin-bottom: 2em;
  margin-top: 0;
}

.subtitle {
  margin: 1em;
  font-weight: 500;
}

.display-section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 5em;
   padding: 15em;
  align-items: center;
  justify-content: center;
  max-width: 130em;
  background-color: #2e2d45;
}

.app-screen {
  height: 20em; 
  border-radius: .5em;
}

.download-section {
  display: flex;
  flex-direction: column;
  padding-left: 2em; 
}

.app-store-anchor {
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.app-store-button {
  border: 1px;
  width: 10rem;
  height: 4rem;
  border-color: white;
  border-style: solid;
  border-radius: 20px;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
}

.app-button-text {
  color: #ffffff;
  flex: 3;
  text-align: left;
} 

.fa-apple {
  text-decoration: none;
  color: #ffffff;
  font-size: 50px;
  flex: 2;
  margin-right: 10px;
} 

.contact-us{
 margin-bottom: 5em;
 padding-bottom: 5em;
} 

.privacy-page {
  padding-left: 100x;
  padding-right: 100px;
}

.policy-section {

}

.privacy-title {

}

.section-title {
  text-align: left;
}
.privacy-paragraph {
  text-align: left;
}

.achievements-page {
 
}

.acheivement-heading{
color: #595959;
}

.achievement-name{
  color: #595959;
}



/* HTML Selectors */

body {
  font-family: 'Montserrat';
  text-align: center;
  background-color: black;
}


h1, h2, h3, h4, h5, h6  {
  font-weight: 900;
}


/* Logo */
.logo {
  font-family: 'Ubuntu';
  font-size: 2.5rem;
  font-weight: bold;
  color: whitesmoke;
  padding-bottom: 2.5rem;
}
.icon-png {
  height: 2.5rem;
  width: 2.5rem; 
  border-radius: 50%;
  margin-left: 1rem; 
}

/* Headings */

.big-heading { 
  font-weight: 900;
  font-size: 3.5rem;
  line-height: 1.5;
  margin: 30px auto 50px auto;
  color: whitesmoke
 
}


/* Containers */

.container-fluid {
  padding: 7% 15%;
}



/* Download Buttons */

.download-button {
  display:flex ;
  flex-direction: row;
  align-items: center;
  justify-content: center;

}

/* Title Section */

#title .container-fluid {
  padding: 3% 15% 7%;
  text-align: left;
}


/* Title Image */

.title-image {
  width: 60%;
  transform: rotate(25deg);
  margin: auto auto -12rem 50px;
}


@media (max-width: 1028px) {

  .title-image {
      transform: rotate(0);
      margin: auto auto -12rem;
  }

  #title {
      text-align: center;
  }
}